import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';

import ProjectList from '../../components/ProjectList';

const Archive = ({ pageContext, data }) => {
  const { metaData } = pageContext;
  const projects = get(data, 'allContentfulProject.edges').map(
    ({ node }) => node
  );

  return (
    <>
      <Helmet title={`Archive | ${metaData.siteName}`} />
      <ProjectList projects={projects} />
    </>
  );
};

export default Archive;

export const pageQuery = graphql`
  query ArchiveQuery {
    allContentfulProject(filter: { slug: { ne: "template-do-not-delete" } }) {
      edges {
        node {
          id
          name
          client
          slug
        }
      }
    }
  }
`;
